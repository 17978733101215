import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton, Space } from 'antd';

import { ContentaText } from 'components/Styled/ContentaText';
import MentorSpecialties from '../../MentorSpecialties';

import { useMentor } from '../../mentorContext';

import './styles.scss';

const MotivateToBeExpert = () => {
  const { advisor, isLoading } = useMentor();
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = useState(false);

  const { consultRegionDetails, summary } = advisor;
  const videoUrl = advisor?.videoUrl || null;
  const coverUrl = advisor?.coverUrl;

  if (isLoading) {
    return (
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton.Node
          active
          style={{
            width: '100%',
            height: '300px',
          }}
        >
          <span />
        </Skeleton.Node>
        <Space className="mt-8">
          <Skeleton.Button active size="small" shape="false" block={false} />
          <Skeleton.Button active size="small" shape="false" block={false} />
          <Skeleton.Button active size="small" shape="false" block={false} />
        </Space>

        <Skeleton active />

        <Space className="mt-8 d-flex">
          <Skeleton.Button
            active
            size="small"
            shape="false"
            block={true}
            style={{
              width: 200,
            }}
          />
        </Space>

        <Skeleton active />
      </section>
    );
  }

  return (
    <section className="motivate-to-be-expert">
      {videoUrl && coverUrl && (
        <div className="mentor-video-wrapper">
          <img src={coverUrl} alt="" className="mentor-video-blur-bg" />
          {showVideo ? (
            <video
              src={videoUrl}
              className="mentor-video"
              controls
              autoPlay
              muted
              playsInline
            />
          ) : (
            <div
              className="mentor-video-thumbnail"
              onClick={() => setShowVideo(true)}
            >
              <img src={coverUrl} alt="Mentor thumbnail" />
              <div className="mentor-video-overlay" />
              <div className="mentor-video-play-bg">
                <div className="mentor-play-button" />
              </div>
            </div>
          )}
        </div>
      )}

      {/* especialidades */}
      <MentorSpecialties />

      {/* about me */}
      <ContentaText color="secondary">{summary}</ContentaText>

      {/* about my area */}
      <ContentaText color="primary" size="large" bold>
        {t('mentorProfile.aboutMyArea')}
      </ContentaText>

      <ContentaText color="secondary">{consultRegionDetails}</ContentaText>
    </section>
  );
};

export default MotivateToBeExpert;
