import { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import { useLocation } from 'react-router-dom';

import AdvisorCard from 'components/AdvisorCard';
import DesktopView from '../DesktopView';
import MentorDetailsHeader from '../MentorDetailsHeader';
import MentorDetailsSchedule from '../MentorDetailsSchedule';
import MobileView from '../MobileView';
import ScrollToTop from 'components/ScrollToTop';

import { useMentor } from '../mentorContext';

import './styles.scss';

import CalendarSchedules from 'components/CalendarSchedules';

function MentorContainer() {
  const { state } = useLocation();

  const {
    availableSchedules,
    isLoading: isLoadingAdvisor,
    loading,
    mentor,
    setAvailableSchedules,
  } = useMentor();
  const [advisorInfo, setAdvisorInfo] = useState({});

  const advisorState = state?.advisor;

  const isDesktop = window.innerWidth >= 1024;
  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    setAdvisorInfo((advisor) => ({
      ...advisor,
      ...advisorState,
      ...mentor,
    }));
  }, [mentor, advisorState]);

  return (
    <div className="travel-expert-details">
      <ScrollToTop />

      <MentorDetailsHeader />

      <div className="mentor-details-container">
        <div className="mentor-details-container__column">
          <AdvisorCard
            advisor={advisorInfo}
            isLoadingAdvisor={isLoadingAdvisor}
          />

          {/* <CalendarSchedules /> */}

          {isDesktop && (
            <MentorDetailsSchedule
              advisor={mentor}
              isLoading={isLoadingAdvisor || loading}
              schedules={availableSchedules}
              setAvailableSchedules={setAvailableSchedules}
            />
          )}
        </div>

        <div className="mentor-details-container__column">
          {isMobile && (
            <MobileView
              advisor={advisorInfo}
              loading={isLoadingAdvisor || loading}
            />
          )}

          {!isMobile && (
            <DesktopView
              advisor={advisorInfo}
              loading={isLoadingAdvisor || loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MentorContainer;
