import { AxiosError } from 'axios';
import { CURRENCIES } from 'constants/currencies';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import { USER_TYPES } from 'constants/types';
import Cookies from 'js-cookie';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services/Auth0Service';
import UserService from 'services/UserService';
import { keyRole } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';
import useSubscription from './contextsHooks/useSubscription';
import useContentaAuth from 'hooks/useContentaAuth';

const UserContext = createContext({});

let timeout;

const helperFields = {
  isAdult: true,
  birthDate: null,
  isValidDate: true,
};

export function UserProvider({ children }) {
  const { subscription, getSubscription, subscriptionError } =
    useSubscription();
  const navigate = useNavigate();

  const [authUser, setAuthUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useState({});
  const [userCurrency, setUserCurrency] = useState('');
  const [userError, setUserError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [whoAmI, setWhoAmI] = useState({});
  const [registerFieldsHelper, setRegisterFieldsHelper] =
    useState(helperFields);
  const [isAuthenticated, setIsAuthenticated] = useState(
    Object.keys(authUser).length > 0
  );

  const { token } = useContentaAuth({ authUser });

  const isRegisterPage =
    window.location.pathname.includes('/register') ||
    window.location.pathname.includes('/redirecting') ||
    window.location.pathname.includes('/guide/register');

  const logout = async () => {
    setLoggedUser({});
    setWhoAmI({});
    setUserRole('');
    navigate('/logout');
  };

  const handleUserContext = (user) => {
    setLoggedUser(user);
  };

  const getUserCurrency = async () => {
    try {
      const response = await UserService.getUserCurrency();
      const countryByCurrency = CURRENCIES.find(
        (currency) => currency.code === response.currencyStandard
      ) || { name: 'USD' };
      setUserCurrency({
        ...response,
        countryName: response.name,
        name: countryByCurrency.name,
      });
    } catch (error) {
      setUserCurrency('USD');
    }
  };

  const handleError = (error) => {
    setWhoAmI({ ...loggedUser, ...authUser, isRegisterPage });

    const { pathname } = window.location;
    if (pathname.includes('/register')) return;

    if (pathname === '/') {
      return;
    }

    if (error instanceof AxiosError) {
      setUserError(error);
      const status = error?.response?.status || 0;
      const isUnloggedPage = isUnloggedRoute(pathname);

      if (isUnloggedPage) {
        setIsLoading(false);
        return;
      }

      const isAdmin = authUser?.[keyRole]?.[0] === USER_TYPES.admin;
      if (status === 404 && !isAdmin) {
        setIsLoading(false);
        navigate('/guide/register');
        return;
      }

      const isAdvisorUserEndpoint =
        error?.response?.request?.responseURL.includes('advisor/user');

      if (isAdvisorUserEndpoint && !isUnloggedPage && !isAdmin) {
        timeout = setTimeout(getLoggedUser, 10000);
        return;
      }
    }
  };

  const getLoggedUser = async () => {
    try {
      const role = authUser[keyRole]?.[0];
      setUserRole(role || '');

      const userRoles = authUser[keyRole] || [];
      const userRolesWithoutAdmin = userRoles.filter(
        (role) => role !== USER_TYPES.admin
      );

      const data = await UserService.getCurrentUser({
        role: userRolesWithoutAdmin[0],
      });
      setWhoAmI({
        ...loggedUser,
        ...authUser,
        ...data,
        isRegisterPage,
      });
      setUserError(null);
    } catch (error) {
      handleError(error);
    }
  };

  const updateUserData = (data) => {
    setWhoAmI({
      ...loggedUser,
      ...data,
    });
  };

  const reloadUserData = async () => {
    await getLoggedUser();
  };

  const fetchUser = async () => {
    setLoggedUser(authUser);
    Promise.all([getLoggedUser(), getSubscription(authUser[keyRole][0])]);
  };

  const authenticateUser = (token = null) => {
    const accessToken = token || Cookies.get(CONTENTA_TOKEN);

    if (!accessToken) {
      setIsLoading(false);
      return;
    }

    authService.client.userInfo(accessToken, function (err, user) {
      if (err) {
        setIsLoading(false);
        Cookies.remove(CONTENTA_TOKEN);
        return;
      }
      if (user) {
        setIsAuthenticated(true);
        setAuthUser(user);
        setIsLoading(false);
      }
    });
  };

  function updateLocalToken(data) {
    if (!data) {
      return;
    }
    Cookies.remove(CONTENTA_TOKEN);
    Cookies.set(CONTENTA_TOKEN, data);
    authenticateUser(data);
  }

  const setToken = async (token) => {
    setLoggedUser({ ...loggedUser, token });
    updateLocalToken(token);
  };

  useEffect(() => {
    const hasUserInfo = Object.keys(authUser).length > 0;
    if (hasUserInfo && !isLoading) {
      fetchUser();
    }
  }, [authUser, isLoading]);

  useEffect(() => {
    getUserCurrency();
    authenticateUser();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (whoAmI?.id) {
      setIsAuthenticated(true);
    }
  }, [whoAmI]);

  useEffect(() => {
    if (token) {
      updateLocalToken(token);
    }
  }, [token]);

  const value = useMemo(
    () => ({
      authUser,
      isLoading,
      isAuthenticated,
      registerFieldsHelper,
      subscription,
      subscriptionError,
      user: loggedUser,
      userCurrency,
      userError,
      userRole,
      whoAmI,
      whoAmILoading: isLoading,
      handleUserContext,
      logout,
      reloadUserData,
      setAuthUser,
      setIsLoading,
      setRegisterFieldsHelper,
      setToken,
      updateUserData,
      getToken: () => Cookies.get(CONTENTA_TOKEN),
    }),
    [
      authUser,
      isAuthenticated,
      isLoading,
      loggedUser,
      registerFieldsHelper,
      subscription,
      subscriptionError,
      userCurrency,
      userError,
      userRole,
      whoAmI,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext(UserContext);
