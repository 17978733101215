import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Rate, Skeleton, Tooltip } from 'antd';

import useCurrencyParse from 'hooks/useCurrencyParse';

import findCountryNameById from 'utils/findCountryNameById';

import { FlagsCodeImage, FlagsCodeRounded } from 'constants/flag-code';
import './styles.scss';

const StarIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99991 11.5672L3.27667 13.9285C3.16726 13.9852 3.06572 14.0082 2.97204 13.9974C2.87905 13.9859 2.78844 13.9522 2.70023 13.8962C2.61134 13.8387 2.54433 13.7576 2.4992 13.6527C2.45407 13.5479 2.44997 13.4333 2.48689 13.3091L3.4777 8.8817L0.200634 5.89779C0.108322 5.81879 0.0474649 5.72436 0.0180618 5.61448C-0.0113412 5.5046 -0.00484512 5.3994 0.0375499 5.29886C0.079945 5.19831 0.136358 5.11573 0.206788 5.05109C0.277903 4.98862 0.373634 4.94624 0.493981 4.92398L4.81828 4.52756L6.50451 0.335016C6.551 0.216522 6.61802 0.131062 6.70554 0.0786372C6.79307 0.0262124 6.89119 0 6.99991 0C7.10864 0 7.2071 0.0262124 7.29531 0.0786372C7.38352 0.131062 7.45019 0.216522 7.49532 0.335016L9.18155 4.52756L13.5048 4.92398C13.6259 4.94553 13.7219 4.98826 13.793 5.05217C13.8642 5.11537 13.9209 5.1976 13.9633 5.29886C14.005 5.3994 14.0112 5.5046 13.9818 5.61448C13.9524 5.72436 13.8915 5.81879 13.7992 5.89779L10.5221 8.8817L11.5129 13.3091C11.5512 13.4319 11.5475 13.5461 11.5017 13.6516C11.4558 13.7572 11.3885 13.8384 11.2996 13.8951C11.2121 13.9525 11.1215 13.9866 11.0278 13.9974C10.9348 14.0082 10.8336 13.9852 10.7242 13.9285L6.99991 11.5672Z"
      fill="#005B99"
    />
  </svg>
);

function AdvisorCard({ advisor, isLoadingAdvisor = false }) {
  const { parseCurrencyValue } = useCurrencyParse();
  const navigate = useNavigate();
  const { name, image, imageUrl, city } = advisor;
  const { t } = useTranslation();

  const country = findCountryNameById({
    acronym: advisor?.addressCountry?.acronym,
  });

  const travelExpertImg = imageUrl || image;

  const redirectToPricingTab = () => {
    navigate({ search: 'tab=scheduleData ' });
  };

  if (isLoadingAdvisor) {
    return <Skeleton avatar paragraph={{ rows: 2 }} />;
  }

  return (
    <div className="travel-expert-card-container advisor-card">
      <div
        style={{ backgroundImage: `url(${travelExpertImg})` }}
        alt="Advisor"
        className="advisor-card__avatar"
      />

      <div className="advisor-card__content">
        <div className="advisor-card__rate">
          <Rate
            count={1}
            value={advisor.rating || 0}
            character={<StarIcon />}
            disabled
          />
          <span className="advisor-card__rate-value">
            {advisor.rating ?? 0}
          </span>
        </div>
        <h3 className="advisor-card__name">{name}</h3>
        <h4 className="advisor-card__country">
          {country} - {city}
        </h4>

        <div className="advisor-card__info">
          <div className="advisor-card__idioms">
            <span className="advisor-card__idioms-text">
              {t('common.idioms')}
            </span>
            <span className="advisor-card__idioms-options">
              {advisor?.languages?.map(({ code }) => (
                <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                  {FlagsCodeImage[code]}
                </Tooltip>
              ))}
            </span>
          </div>
        </div>

        <div className="advisor-card__price">
          <span
            className="advisor-card__price-value pointer"
            onClick={() => redirectToPricingTab()}
          >
            {parseCurrencyValue(advisor.valueHourVirtual)}
          </span>
          <span className="advisor-card__price-text">
            {t('mentorProfile.priceHour')}
          </span>
        </div>
      </div>
    </div>
  );
}

export default AdvisorCard;
