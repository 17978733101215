import { useEffect, useState } from 'react';

import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserOutlined } from '@ant-design/icons';

import { ContentaTab } from 'components/Styled/ContentaTab';
import { ContentaText } from 'components/Styled/ContentaText';

import MotivateToBeExpert from './components/MotivateToBeExpert';
import Prices from './components/Prices';

import * as S from './styles';

const DesktopView = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('whyAmI');

  const tabsContent = [
    {
      id: 'whyAmI',
      title: (
        <div className="tab-title">
          <UserOutlined />
          <ContentaText
            className="m-0"
            size="large"
            color={activeTab === 'whyAmI' ? 'primary' : 'secondary'}
            bold={activeTab === 'whyAmI'}
          >
            {t('mentorProfile.whyAmI')}
          </ContentaText>
        </div>
      ),
      children: <MotivateToBeExpert />,
    },
    {
      id: 'scheduleData',
      title: (
        <ContentaText
          className="m-0"
          size="medium"
          color={activeTab === 'scheduleData' ? 'primary' : 'secondary'}
          bold={activeTab === 'scheduleData'}
        >
          $ {t('mentorProfile.pricing')}
        </ContentaText>
      ),
      children: <Prices />,
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  return (
    <S.RightContent>
      <ContentaTab
        className={`schedule-modal__content tab-full`}
        onTabClick={(key) => setActiveTab(key)}
        activeKey={activeTab}
      >
        {tabsContent.map((tab) => (
          <Tabs.TabPane tab={tab.title} key={tab.id}>
            {tab.children}
          </Tabs.TabPane>
        ))}
      </ContentaTab>
    </S.RightContent>
  );
};

export default DesktopView;
