import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton } from 'antd';

import ScheduleModal from 'components/ScheduleModal';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';

import MentorAbout from '../MentorAbout';
import MentorEvaluations from '../MentorEvaluations';
import MentorScheduleMeet from '../MentorScheduleMeet';
import MentorSpecialties from '../MentorSpecialties';

import { useMentor } from '../mentorContext';

import './styles.scss';

const MobileView = () => {
  const [showVideo, setShowVideo] = useState(false);

  const {
    advisor,
    availableSchedules,
    isModalVisible,
    loading,
    loadingSchedules,
    setAvailableSchedules,
    setIsModalVisible,
  } = useMentor();

  const videoUrl = advisor?.videoUrl || null;
  const { t } = useTranslation();

  const renderCTA = () => {
    if (loading) {
      return (
        <div className="mentor-details__cta">
          <Skeleton.Button active size="large" />
        </div>
      );
    }

    return (
      <div className="mentor-details__cta">
        <ContentaButtonPrimary onClick={() => setIsModalVisible(true)}>
          {t('mentorProfile.bookTime')}
        </ContentaButtonPrimary>
      </div>
    );
  };

  return (
    <>
      {renderCTA()}
      {videoUrl && advisor?.coverUrl && (
        <div className="advisor-mobile-video-wrapper">
          <img
            src={advisor.coverUrl}
            alt=""
            className="advisor-mobile-video-blur-bg"
          />
          {showVideo ? (
            <video
              className="advisor-mobile-video"
              controls
              autoPlay
              muted
              playsInline
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          ) : (
            <div
              className="advisor-mobile-video-thumbnail"
              onClick={() => setShowVideo(true)}
            >
              <img src={advisor.coverUrl} alt="Mentor thumbnail" />
              <div className="advisor-mobile-video-overlay" />
              <div className="mentor-video-play-bg">
                <div className="advisor-mobile-play-button" />
              </div>
            </div>
          )}
        </div>
      )}

      <MentorSpecialties />

      <MentorAbout />

      <MentorScheduleMeet />

      <MentorEvaluations />

      <div className="mb-24">{renderCTA()}</div>

      <ScheduleModal
        advisor={advisor}
        availableSchedules={availableSchedules}
        isLoading={loadingSchedules}
        isOpen={isModalVisible}
        setAvailableSchedules={setAvailableSchedules}
        setOpen={setIsModalVisible}
      />
    </>
  );
};

export default MobileView;
